.default {
    --primary-100: #666e82;
    --primary-200: #515971;
    --primary-300: #3b455f;
    --primary-400: #25304d;
    --primary-500: #192038;
    --primary-600: #1e263e;
    --primary-700: #1a2236;
    --primary-800: #161d2e;
    --primary-900: #131827;
  
    --accent-100: #dd789c;
    --accent-200: #d8658e;
    --accent-300: #d35180;
    --accent-400: #ce3e72;
    --accent-500: #b22c5d;
    --accent-600: #a5325b;
    --accent-700: #902b50;
    --accent-800: #7c2544;
    --accent-900: #671f39;
  
    --secondary-100: #e6fffa;
    --secondary-200: #b2f5ea;
    --secondary-300: #81e6d9;
    --secondary-400: #4fd1c5;
    --secondary-500: #38b2ac;
    --secondary-600: #319795;
    --secondary-700: #2c7a7b;
    --secondary-800: #285e61;
    --secondary-900: #234e52;
  
    --neutral-100: #f7fafc;
    --neutral-200: #edf2f7;
    --neutral-300: #e2e8f0;
    --neutral-400: #cbd5e0;
    --neutral-500: #a0aec0;
    --neutral-600: #718096;
    --neutral-700: #4a5568;
    --neutral-800: #2d3748;
    --neutral-900: #1a202c;
  
    --light-100: #ffffff;

    --sol-100: #f4f4f4;
    --sol-200: #f7f6f0;
    --sol-300: #222222;
    --sol-400: #333333;
    --sol-500: #404040;
    --sol-600: #b19a6a;
    --sol-700: #da0f0f;
    --sol-800: #2c8a4c;
    --sol-900:#F6F6F6;
    
    --sand-100:#666666;
    --sand-200:#268936;
    --sand-300:#E7FFED;
    --sand-400: #F8F8F8;
    --sand-500: #191919;
    --sand-600: #f3f3f3;
    --sand-700: #1E1E1E;

  }