@tailwind base;
@tailwind components;
@tailwind utilities;

@import './themes/default.css';

html {
  @apply p-0;
  /* For tailwind Dialog styling override */
}

/* default font family and color */
body {
  color: var(--sol-300);
  font-family: 'Montserrat', sans-serif;
}

@media (min-width: 768px) {
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}

@media (max-width: 480px) {
  .xsMobLogo a svg {
    width: 145px;
  }
}

form input[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='text-black-100' xmlns='http://www.w3.org/2000/svg'><path d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/></svg>");
}

.select-accent {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIGZpbGw9J25vbmUnIHZpZXdCb3g9JzAgMCAyMCAyMCc+PHBhdGggc3Ryb2tlPScjY2UzZTcyJyBzdHJva2UtbGluZWNhcD0ncm91bmQnIHN0cm9rZS1saW5lam9pbj0ncm91bmQnIHN0cm9rZS13aWR0aD0nMS41JyBkPSdNNiA4bDQgNCA0LTQnLz48L3N2Zz4K');
}

@layer base {

  /* Form default styling */
  input[type='text'].input-text-primary,
  input[type='password'].input-text-primary {
    @apply mb-3 text-base w-full rounded-[3px] border border-black-100/[.20] bg-white focus:border-gray-200 focus:shadow focus:shadow-black-100/[.20] focus:ring-gray-200 sm:w-auto;
  }

  select.input-text-primary {
    @apply mb-3 w-full rounded-[3px] text-base border border-black-100/[.20] bg-white focus:border-gray-200 focus:shadow focus:shadow-black-100/[.20] focus:ring-gray-200 sm:w-auto;
  }

  .btn {
    @apply mb-3 w-full rounded-[6px] border px-7 text-sm font-semibold sm:mb-0 sm:w-auto focus:outline-offset-2;
  }

  .btn-primary-large {
    @apply btn bg-sol-500 py-3 text-white hover:bg-sol-400 focus:outline-offset-2;
  }

  .btn-primary-small {
    @apply btn bg-sol-500 py-2 text-white hover:bg-sol-400 focus:outline-offset-2;
  }

  .btn-secondary {
    @apply btn border-sol-300 bg-white py-3 text-sol-300 hover:border-sol-400 hover:bg-white hover:text-sol-400 focus:outline-offset-2;
  }

  .btn-cancel {
    @apply mb-3 w-full bg-white px-7 text-base font-normal text-black-100 underline hover:bg-white sm:mb-0 sm:w-auto focus:outline-offset-2;
  }
}

@layer utilities {
  .relative-width {
    width: 100%;
    left: 0;
    right: 0;
    margin-left: 0px;
    margin-right: 0px;
  }

  .fixed-screen-width {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  .markdown {
    @apply max-w-none overflow-x-hidden;
    @apply mx-auto pb-10 text-neutral-800 prose-h1:text-neutral-800 prose-h2:text-neutral-800 prose-h3:text-neutral-800 prose-h4:text-neutral-800 lg:prose-lg;
    @apply prose-a:text-accent-600 prose-blockquote:text-neutral-700;
    @apply prose-code:rounded prose-code:bg-neutral-200 prose-code:p-1 prose-code:text-accent-600 prose-pre:bg-neutral-200;
    @apply prose-img:rounded-sm;
    @apply prose-strong:font-bold prose-strong:text-neutral-800;
    @apply prose-ol:list-decimal;
    @apply prose-ul:list-disc;
    @apply prose-li:text-neutral-800;
  }
}

/* This used to remove last pipe in mega menu */
.menuList .listBar {
  display: inline-block;
  align-self: center;
  background: rgba(0, 0, 0, 0.3);
  height: 21px;
  width: 1px;
}

.menuList .listBar:last-child {
  display: none;
}

.requiredLabel:after {
  content: '*';
  color: #da0f0f;
  font-size: 14px;
  position: relative;
  bottom: 6px;
}

.quickView {
  display: none;
  transition: 0.3s;
}

.productQuickView:hover .quickView {
  display: block;
  transition: 0.3s;
}

.checked-icon {
  display: none;
}

.filterNavCheckbox:checked~.checked-icon {
  display: block;
}

.linear-gray {
  background: linear-gradient(270deg, #8d8d8d 0%, #ebebeb 100%);
}

.linear-blue {
  background: linear-gradient(to right, #65cdf4 0%, #2b489a 100%);
}

.linear-default {
  background: linear-gradient(270deg, #00e1ff 0%, #ebebeb 100%);
}

.linear-neutrals {
  background: rgb(229, 209, 200);
}

.linear-red {
  background: linear-gradient(270deg, #b92525 0%, #f98080 100%);
}

.linear-red-200 {
  background: linear-gradient(270deg, #ffa892 0%, #ffd7cd 100%);
}

.linear-black-200 {
  background: linear-gradient(270deg, #413939 0%, #625e5e 100%);
}

.linear-black {
  background: linear-gradient(to right, #231f20 0%, #231f20 100%);
}

.linear-multi {
  background: url(https://cdn.shadesoflight.com/assets/weblinc/store_front/color_filter_multi_colored-d7701283ee86943f564c0ff679f21a9c2736472ad234d5f108b7d5eebb8d90cc.jpg) no-repeat center;
  background-size: contain;
}

.linear-white {
  background: linear-gradient(to right, #fff 0%, #fff 100%);
  box-shadow: 0 0 0 1px #e5e5e4;
}

.linear-clear {
  background: linear-gradient(to right, #f7f7f7 0%, #f7f7f7 100%);
  box-shadow: 0 0 0 1px #e5e5e4;
}

.linear-matte {
  background: url(https://cdn.shadesoflight.com/media/W1siZiIsIjIwMjIvMDEvMDcvMTEvMTEvNDQvODk2L0ZBMTYwMTYuUy5GQTE2MDE2QksuanBnIl0sWyJwIiwic3dhdGNoIl1d/FA16016.S.FA16016BK.jpg?sha=7c207ca07869b74b) no-repeat center;
  background-size: contain;
}

.linear-satin {
  background: url(https://cdn.shadesoflight.com/media/W1siZiIsIjIwMjEvMDgvMDUvMTYvMTkvNTYvOTIxL0ZBMTYwMTYuUy5GQTE2MDE2U04uanBnIl0sWyJwIiwic3dhdGNoIl1d/FA16016.S.FA16016SN.jpg?sha=c873d0ab4015ef9e) no-repeat center;
  background-size: contain;
}

.linear-oiled {
  background: url(https://cdn.shadesoflight.com/media/W1siZiIsIjIwMjEvMDgvMDUvMTYvMTkvNTUvNDg1L0ZBMTYwMTYuUy5GQTE2MDE2QlouanBnIl0sWyJwIiwic3dhdGNoIl1d/FA16016.S.FA16016BZ.jpg?sha=4589885000f5b3cb) no-repeat center;
  background-size: contain;
}

.linear-aqua {
  background: linear-gradient(270deg, #00ffff 0%, #cdbcff 100%);
}

.linear-pink {
  background: linear-gradient(270deg, #fdaed4 0%, #ffdeee 100%);
}

.linear-brown {
  background: linear-gradient(to right, #9e6407 0%, #6d3531 100%);
}

.linear-purple {
  background: linear-gradient(270deg, #8c6ce6 0%, #cdbcff 100%);
}

.linear-orange-900 {
  background: linear-gradient(270deg, #b04e2d 0%, #fab29a 100%);
}

.linear-pink-200 {
  background: linear-gradient(270deg, #ffdeee 0%, #f0b5d1 100%);
}

.linear-yellow-200 {
  background: linear-gradient(270deg, #d5af4f 0%, #e6ce91 100%);
}

.linear-green-500 {
  background: linear-gradient(270deg, #46985d 0%, #8edca4 100%);
}

.linear-green {
  background: linear-gradient(to right, #a9d26e 0%, #007445 100%);
}

.linear-yellow {
  background: linear-gradient(to right, #fff14d 0%, #e9b327 100%);
}

.linear-orange {
  background: linear-gradient(to right, #f6c084 0%, #e15f2c 100%);
}

.linear-sky-900 {
  background: linear-gradient(270deg, #4061b5 0%, #9eb3eb 100%);
}

.customtablescrollbar::-webkit-scrollbar {
  width: 5px;
}

.customtablescrollbar::-webkit-scrollbar-track {
  background: #fff;
}

.customtablescrollbar::-webkit-scrollbar-thumb {
  background: #ccc;
}

.customtablescrollbar::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

.checkoutProgressBar li:last-child .progressBarSection:after {
  display: none;
}

.checkoutProgressBar li:last-child {
  width: auto;
}

.checkoutProgressBar li:last-child .progressBarSection {
  width: 31px;
}

.color-checkbox-ul input:checked+div {
  @apply outline outline-offset-2 outline-black-900;
}

.color-checkbox-ul input:checked+div {
  outline-width: 1px;
}

.color-checkbox-ul input:checked+div svg {
  @apply block;
}

.sortByBtn select {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  background: transparent;
  z-index: 1;
  font-size: 14px;
  color: #222222;
  line-height: 17px;
  position: relative;
  cursor: pointer;
}

.sortByBtn select:focus {
  outline: 1px solid black;
  box-shadow: none;
}

.clearAllBtn div>button {
  text-decoration: underline;
  font-weight: 400;
}

.productSearchContent .ais-Hits ul.ais-Hits-list {
  display: grid;
  grid-gap: 14px;
}

.staticBreakWord {
  word-break: break-word;
}

.staticPadding {
  padding-left: 30px;
  padding-right: 30px;
}

.osano-cm-widget {
  display: none;
}

ol.list-decimal.listNumber {
  list-style: decimal;
  margin-left: 20px;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
    font-size: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
    font-size: inherit;
  }
}

/* Chrome, Safari, Edge, Opera */
.input-number::-webkit-outer-spin-button,
.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input-number[type=number] {
  -moz-appearance: textfield;
}